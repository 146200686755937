<template>
  <q-avatar
    class="profile-avatar items-center bg-green"
    :size="listmode ? '40px !important' : '180px'"
    @click="listmode ? emitClick() : uploader ? file.click() : null"
  >
    <!-- style="display: block; margin: auto" -->

    <div
      v-if="!selected"
      class="text-white text-h4"
      :style="{
        'font-size': listmode ? '1.3em !important' : '1.1em !important',
        'line-height': 'inherit',
      }"
    >
      {{
        `${text1 ? text1.toUpperCase().charAt(0) : ""}${
          text2 ? text2.toUpperCase().charAt(0) : ""
        }`
      }}
    </div>
    <q-icon v-else name="r_done" size="md" color="white" />
    <input
      type="file"
      multiple
      name="fields[assetsFieldHandle][]"
      id="assetsFieldHandle"
      class="w-px h-px opacity-0 overflow-hidden absolute"
      @change="FileUpload"
      ref="file"
      accept="image/png, image/jpeg"
      style="display: none"
    />
  </q-avatar>
</template>

<style lang="scss">
.profile-avatar {
  box-shadow: 0px 0px 2px #222;
}
</style>

<script>
import { ref } from "vue";

export default {
  name: "ProfileAvatar",
  props: {
    src: {
      type: String,
      default: null,
    },
    text1: {
      type: String,
      default: null,
    },
    text2: {
      type: String,
      default: null,
    },
    empid: {
      type: Number,
      default: 0,
    },
    listmode: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    uploader: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["refresh", "click"],
  setup(props, context) {
    const file = ref(null);
    const filelist = ref([]);

    const FileUpload = () => {
      filelist.value = [...file.value.files];
      let formData = new FormData();
      formData.append("file", filelist.value[0]);
    };

    const emitClick = () => {
      console.log(props);
      context.emit("click");
    };

    return {
      file,
      filelist,
      FileUpload,
      emitClick,
    };
  },
};
</script>
